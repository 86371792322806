import ApplicationLayout from '../../layouts/applicationlayout/ApplicationLayout';

const TermsOfServiceParagraph = ({ children }) => <p className="pb-2 pt-2 font-semibold">{children}</p>;

const SectionHeading = ({ children }) => <h2 className="font-bold text-[#0a7248]">{children}</h2>;

export default function TermsOfServicePage() {
    return (
        <ApplicationLayout>
            <div className="flex justify-center">
                <div className="p-5 lg:w-2/4">
                    <h1 className="font-bold text-[#0a7248]">Terms of service</h1>
                    <span>
                        <SectionHeading>Last updated: June 6th, 2023</SectionHeading>
                        <TermsOfServiceParagraph>
                            These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with Putting Sim
                            mobile application (the "Service") operated by PUTTINGSIM LTD ("us", "we", or "our").
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            Please read these Terms and Conditions carefully before using our Putting Sim mobile application (the
                            "Service").
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            Your access to and use of the Service is conditioned on your acceptance of and compliance
                            with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part
                            of the terms then you may not access the Service.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Subscriptions</SectionHeading>
                        <TermsOfServiceParagraph>
                            Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in
                            advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly
                            or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            At the end of each Billing Cycle, your Subscription will automatically renew under the exact same
                            conditions unless you cancel it or PUTTINGSIM LTD cancels it. You may cancel your Subscription
                            renewal either through your online account management page or by contacting PUTTINGSIM LTD
                            customer support team.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            A valid payment method, including credit card, is required to process the payment for your Subscription.
                            You shall provide PUTTINGSIM LTD with accurate and complete billing information including full
                            name, address, state, zip code, telephone number, and a valid payment method information. By submitting
                            such payment information, you automatically authorize PUTTINGSIM LTD to charge all Subscription
                            fees incurred through your account to any such payment instruments.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            Should automatic billing fail to occur for any reason, PUTTINGSIM LTD will issue an electronic
                            invoice indicating that you must proceed manually, within a certain deadline date, with the full payment
                            corresponding to the billing period as indicated on the invoice.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Fee Changes</SectionHeading>
                        <TermsOfServiceParagraph>
                            PUTTINGSIM LTD, in its sole discretion and at any time, may modify the Subscription fees for the
                            Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            PUTTINGSIM LTD will provide you with a reasonable prior notice of any change in Subscription fees to
                            give you an opportunity to terminate your Subscription before such change becomes effective.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            Your continued use of the Service after the Subscription fee change comes into effect constitutes your
                            agreement to pay the modified Subscription fee amount.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Refunds</SectionHeading>
                        <TermsOfServiceParagraph>
                            Certain refund requests for Subscriptions may be considered by PUTTINGSIM LTD on a case-by-case basis and
                            granted in sole discretion of PUTTINGSIM LTD.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Accounts</SectionHeading>
                        <TermsOfServiceParagraph>
                            When you create an account with us, you must provide us information that is accurate, complete, and
                            current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                            termination of your account on our Service.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            You are responsible for safeguarding the password that you use to access the Service and for any
                            activities or actions under your password, whether your password is with our Service or a third-party
                            service.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            You agree not to disclose your password to any third party. You must notify us immediately upon becoming
                            aware of any breach of security or unauthorized use of your account.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Intellectual Property</SectionHeading>
                        <TermsOfServiceParagraph>
                            The Service and its original content, features and functionality are and will remain the exclusive
                            property of PUTTINGSIM LTD and its licensors. The Service is protected by copyright, trademark, and
                            other laws of both the United Kingdom and foreign countries. Our trademarks and trade dress may not be
                            used in connection with any product or service without the prior written consent of PUTTINGSIM LTD.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Links To Other Web Sites</SectionHeading>
                        <TermsOfServiceParagraph>
                            Our Service may contain links to third-party web sites or services that are not owned or controlled
                            by PUTTINGSIM LTD.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            PUTTINGSIM LTD has no control over, and assumes no responsibility for, the content, privacy policies,
                            or practices of any third party web sites or services. You further acknowledge and agree that
                            PUTTINGSIM LTD shall not be responsible or liable, directly or indirectly, for any damage or loss caused
                            or alleged to be caused by or in connection with use of or reliance on any such content, goods or
                            services available on or through any such web sites or services.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites
                            or services that you visit.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Termination</SectionHeading>
                        <TermsOfServiceParagraph>
                            We may terminate or suspend your account immediately, without prior notice or liability, for any reason
                            whatsoever, including without limitation if you breach the Terms.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account,
                            you may simply discontinue using the Service.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Limitation Of Liability</SectionHeading>
                        <TermsOfServiceParagraph>
                            In no event shall PUTTINGSIM LTD, nor its directors, employees, partners, agents, suppliers, or affiliates,
                            be liable for any indirect, incidental, special, consequential or punitive damages, including without
                            limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access
                            to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on
                            the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration
                            of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any
                            other legal theory, whether or not we have been informed of the possibility of such damage, and even if a
                            remedy set forth herein is found to have failed of its essential purpose.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Disclaimer</SectionHeading>
                        <TermsOfServiceParagraph>
                            Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE"
                            basis. The Service is provided without warranties of any kind, whether express or implied, including,
                            but not limited to, implied warranties of merchantability, fitness for a particular purpose,
                            non-infringement or course of performance.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            PUTTINGSIM LTD its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will
                            function uninterrupted, secure or available at any particular time or location; b) any errors or defects
                            will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of
                            using the Service will meet your requirements.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Governing Law</SectionHeading>
                        <TermsOfServiceParagraph>
                            These Terms shall be governed and construed in accordance with the laws of United Kindom, without regard
                            to its conflict of law provisions.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                            rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
                            provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us
                            regarding our Service, and supersede and replace any prior agreements we might have between us regarding
                            the Service.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Changes</SectionHeading>
                        <TermsOfServiceParagraph>
                            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision
                            is material we will try to provide at least 30 days notice prior to any new terms taking effect. What
                            constitutes a material change will be determined at our sole discretion.
                        </TermsOfServiceParagraph>
                        <TermsOfServiceParagraph>
                            By continuing to access or use our Service after those revisions become effective, you agree to be bound
                            by the revised terms. If you do not agree to the new terms, please stop using the Service.
                        </TermsOfServiceParagraph>
                    </span>
                    <span>
                        <SectionHeading>Contact Us</SectionHeading>
                        <TermsOfServiceParagraph>
                            If you have any questions about these Terms, please contact us.
                        </TermsOfServiceParagraph>
                    </span>
                </div>
            </div>
        </ApplicationLayout>
    );
}