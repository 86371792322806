import './App.css';
import { Route } from 'react-router-dom';
import LandingPage from './pages/landingpage/LandingPage';
import PrivacyPage from './pages/privacypage/PrivacyPage';
import TermsOfServicePage from './pages/termsofservicepage/TermsOfServicePage';

export default function App() {
  return (
      <>
          <Route exact path="/" component={LandingPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/termsofservice" component={TermsOfServicePage} />
      </>
  );
}