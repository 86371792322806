import Header from '../../components/header/Header';

export default function ApplicationLayout({ children }) {
    return (
        <div className="flex flex-col h-screen w-full">
            <div class="-z-50 absolute bottom-0 left-0 right-0 top-0 grid place-items-center opacity-15 select-none">
                <img src={require('../../assets/golf-app-logo-no-background.png')} alt="" />
            </div>
            <div className="w-full z-10">
                <Header />
            </div>
            <div className="flex-1 overflow-y-scroll select-none">
                <div className="z-1000">
                    {children}
                </div>
            </div>
        </div>
    );
}