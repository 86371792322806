import ApplicationLayout from '../../layouts/applicationlayout/ApplicationLayout';

const PrivacyParagraph = ({ children }) => <p className="pb-2 pt-2 font-semibold">{children}</p>;

const SectionHeading = ({ children }) => <h2 className="font-bold text-[#0a7248]">{children}</h2>;

const SectionSubHeading = ({ children }) => <h3 className="font-bold text-[#0a7248]">{children}</h3>;

export default function PrivacyPage() {
    return (
        <ApplicationLayout>
            <div className="flex justify-center">
                <div className="p-5 lg:w-2/4">
                    <h1 className="font-bold text-[#0a7248]">Privacy policy</h1>
                    <span>
                        <SectionHeading>Effective date: June 6th, 2023</SectionHeading>
                        <PrivacyParagraph>
                            PUTTINGSIM LTD ("us", "we", or "our") operates the website and the Putting Sim mobile application
                            (the "Service").
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            This page informs you of our policies regarding the collection, use, and disclosure of personal data
                            when you use our Service and the choices you have associated with that data.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            We use your data to provide and improve the Service. By using the Service, you agree to the collection
                            and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy,
                            terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
                        </PrivacyParagraph>
                    </span>
                    <span>
                        <SectionHeading>Information Collection And Use</SectionHeading>
                        <PrivacyParagraph>
                            We collect several different types of information for various purposes to provide and improve our
                            Service to you.
                        </PrivacyParagraph>
                    </span>
                    <span>
                        <SectionHeading>Types of Data Collected</SectionHeading>
                        <SectionSubHeading>Personal Data</SectionSubHeading>
                        <PrivacyParagraph>
                            While using our Service, we may ask you to provide us with certain personally identifiable information
                            that can be used to contact or identify you ("Personal Data"). Personally identifiable information may
                            include, but is not limited to:
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            Email address First name and last name Cookies and Usage Data
                        </PrivacyParagraph>
                        <SectionSubHeading>Usage Data</SectionSubHeading>
                        <PrivacyParagraph>
                            We may also collect information that your browser sends whenever you visit our Service or when you access
                            the Service by or through a mobile device ("Usage Data").
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            This Usage Data may include information such as your computer's Internet Protocol address
                            (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and
                            date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            When you access the Service by or through a mobile device, this Usage Data may include information such
                            as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device,
                            your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and
                            other diagnostic data.
                        </PrivacyParagraph>
                        <SectionSubHeading>Tracking & Cookies Data</SectionSubHeading>
                        <PrivacyParagraph>
                            We use cookies and similar tracking technologies to track the activity on our Service and hold certain
                            information.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies
                            are sent to your browser from a website and stored on your device. Tracking technologies also used are
                            beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However,
                            if you do not accept cookies, you may not be able to use some portions of our Service.
                        </PrivacyParagraph>
                        <SectionSubHeading>Examples of Cookies we use:</SectionSubHeading>
                        <PrivacyParagraph>
                            Session Cookies. We use Session Cookies to operate our Service. Preference Cookies. We use Preference
                            Cookies to remember your preferences and various settings. Security Cookies. We use Security Cookies
                            for security purposes. Use of Data
                        </PrivacyParagraph>          
                    </span>
                    <span>
                        <SectionHeading>PUTTINGSIM LTD uses the collected data for various purposes:</SectionHeading>
                        <PrivacyParagraph>
                            To provide and maintain our Service
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            To notify you about changes to our Service To allow you to participate in interactive features of our
                            Service when you choose to do so To provide customer support To gather analysis or valuable information
                            so that we can improve our Service To monitor the usage of our Service To detect, prevent and address
                            technical issues
                        </PrivacyParagraph>
                    </span>
                    <span>
                        <SectionHeading>Transfer Of Data</SectionHeading>
                        <PrivacyParagraph>
                            Your information, including Personal Data, may be transferred to and maintained on computers located
                            outside of your state, province, country or other governmental jurisdiction where the data protection
                            laws may differ than those from your jurisdiction.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            If you are located outside United Kingdom and choose to provide information to us, please note that we
                            transfer the data, including Personal Data, to United Kingdom and process it there.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            Your consent to this Privacy Policy followed by your submission of such information represents your
                            agreement to that transfer.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            PUTTINGSIM LTD will take all steps reasonably necessary to ensure that your data is treated securely and
                            in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
                            organization or a country unless there are adequate controls in place including the security of your data
                            and other personal information.
                        </PrivacyParagraph>
                    </span>
                    <span>
                        <SectionHeading>Disclosure Of Data</SectionHeading>
                        <SectionSubHeading>Business Transaction</SectionSubHeading>
                        <PrivacyParagraph>
                            If PUTTINGSIM LTD is involved in a merger, acquisition or asset sale, your Personal Data may be
                            transferred. We will provide notice before your Personal Data is transferred and becomes subject to a
                            different Privacy Policy.
                        </PrivacyParagraph>
                        <SectionSubHeading>Disclosure for Law Enforcement</SectionSubHeading>
                        <PrivacyParagraph>
                            Under certain circumstances, PUTTINGSIM LTD may be required to disclose your Personal Data if required to
                            do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
                        </PrivacyParagraph>
                        <SectionSubHeading>Legal Requirements</SectionSubHeading>
                        <PrivacyParagraph>
                            PUTTINGSIM LTD may disclose your Personal Data in the good faith belief that such action is necessary to:
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            To comply with a legal obligation To protect and defend the rights or property of PUTTINGSIM LTD To
                            prevent or investigate possible wrongdoing in connection with the Service To protect the personal safety
                            of users of the Service or the public To protect against legal liability
                        </PrivacyParagraph>
                        <SectionSubHeading>Security Of Data</SectionSubHeading>
                        <PrivacyParagraph>
                            The security of your data is important to us, but remember that no method of transmission over the
                            Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable
                            means to protect your Personal Data, we cannot guarantee its absolute security.
                        </PrivacyParagraph>
                        <SectionSubHeading>Service Providers</SectionSubHeading>
                        <PrivacyParagraph>
                            We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to
                            provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how
                            our Service is used.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            These third parties have access to your Personal Data only to perform these tasks on our behalf and are
                            obligated not to disclose or use it for any other purpose.
                        </PrivacyParagraph>
                        <SectionSubHeading>Analytics</SectionSubHeading>
                        <PrivacyParagraph>
                            We may use third-party Service Providers to monitor and analyze the use of our Service.
                        </PrivacyParagraph>
                        <SectionSubHeading>Google Analytics</SectionSubHeading>
                        <PrivacyParagraph>
                            Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
                            Google uses the data collected to track and monitor the use of our Service. This data is shared with
                            other Google services. Google may use the collected data to contextualize and personalize the ads of its
                            own advertising network.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            You can opt-out of having made your activity on the Service available to Google Analytics by installing
                            the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
                            analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:
                            http://www.google.com/intl/en/policies/privacy/
                        </PrivacyParagraph>
                        <SectionSubHeading>Links To Other Sites</SectionSubHeading>
                        <PrivacyParagraph>
                            Our Service may contain links to other sites that are not operated by us. If you click on a third party
                            link, you will be directed to that third party's site. We strongly advise you to review the Privacy
                            Policy of every site you visit.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            We have no control over and assume no responsibility for the content, privacy policies or practices of
                            any third party sites or services.
                        </PrivacyParagraph>
                    </span>
                    <span>
                        <SectionHeading>Children's Privacy</SectionHeading>
                        <PrivacyParagraph>
                            Our Service does not address anyone under the age of 13 ("Children").
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            We do not knowingly collect personally identifiable information from anyone under the age of 13. If you
                            are a parent or guardian and you are aware that your Children has provided us with Personal Data, please
                            contact us. If we become aware that we have collected Personal Data from children without verification
                            of parental consent, we take steps to remove that information from our servers.
                        </PrivacyParagraph>
                    </span>
                    <span>
                        <SectionHeading>Changes To This Privacy Policy</SectionHeading>
                        <PrivacyParagraph>
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                            Privacy Policy on this page.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective
                            and update the "effective date" at the top of this Privacy Policy.
                        </PrivacyParagraph>
                        <PrivacyParagraph>
                            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
                            effective when they are posted on this page.
                        </PrivacyParagraph>
                    </span>
                    <span>
                        <SectionHeading>Contact Us</SectionHeading>
                        <PrivacyParagraph>
                            If you have any questions about this Privacy Policy, please contact us.
                        </PrivacyParagraph>
                    </span>
                </div>
             </div>
        </ApplicationLayout>
    );
}