import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "How do I set up my device to record putting metrics?",
        answer:
            "Place the phone with the app open 3ft (putter length) behind the straight putt and ensure the grey capture box covers the hitting zone, place a towel or jumper at 6ft to stop the ball. Detailed setup instructions can be found in the help section within the app.",
    },
    {
        question: "Will I need any extra equipment to use the app?",
        answer:
            "The phone will need to stand on its own, We recommend this tripod.",
        link: "https://www.amazon.co.uk/gp/product/B01N6713N8/ref=ppx_yo_dt_b_asin_title_o01_s00?ie=UTF8&psc=1",
    },
    {
        question: "Will a graphical simulator be added to the app?",
        answer:
            "Yes! work is currently ongoing to develop the graphics to simulate how the ball would roll on a putting green. More details to come soon...",
    },
    // More questions...
]

export default function Faq() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-20 sm:py-20 lg:px-8 lg:py-20">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-[#0a7248]">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                            {faq.link && (<a href={faq.link} target="_blank" className="italic text-blue-700">tripod on amazon</a>)}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}