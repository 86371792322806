const features = [
    { name: 'Total roll', description: 'Calculated from a 6ft putt, use this to work on lag putting from multiple distances.' },
    { name: 'Launch direction', description: 'Pushing or pulling those 3ft putts? work on this with the aid of this metric.' },
    { name: 'Ball speed', description: 'Calculated from a 6ft putt, use this to work on lag putting in conjunction with total roll.' },
    { name: 'Face angle', description: 'Having an open or closed face influences launch direction.' },
    { name: 'Swing path', description: 'Having a non-neutral swing path influences launch direction.' },
    { name: 'Face to path', description: 'Having a non-neutral face to path can created unwanted spin on putts.' },
]

export default function ProductFeatures() {
    return (
        <div className="bg-white">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-[#0a7248] sm:text-4xl">Technical Specifications</h2>
                    <p className="mt-4 text-gray-500">
                        PuttingSim captures 6 key metrics from each putt to allow you to enhance your practice sessions. Line and pace are
                        imperative to being a good putter, these metrics allow you work on launch direction and lag putting at home.
                    </p>

                    <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="border-t border-gray-200 pt-4">
                                <dt className="font-medium text-[#0a7248]">{feature.name}</dt>
                                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="grid grid-cols-2 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
                    <img
                        src={require('../../../../assets/PuttingSim-Capture-Example.jpg')}
                        alt="Putting capture example."
                        className="rounded-lg bg-gray-100"
                    />
                    <img
                        src={require('../../../../assets/PuttingSim-Setup-Example.jpg')}
                        alt="Putting setup example."
                        className="rounded-lg bg-gray-100"
                    />
                </div>
            </div>
        </div>
    )
}