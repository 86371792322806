import ApplicationLayout from '../../layouts/applicationlayout/ApplicationLayout';
import Hero from './partials/hero/Hero';
import ProductFeatures from './partials/productfeatures/ProductFeatures';
import Pricing from './partials/pricing/Pricing';
import Faq from './partials/faq/Faq';
import ContactUs from './partials/contactus/ContactUs';

export default function LandingPage() {
    return (
        <ApplicationLayout>
            <Hero />
            <ProductFeatures />
            <Pricing />
            <Faq />
            <ContactUs />
        </ApplicationLayout>
    );
}